@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}

.sci-venues-table {
    .mat-sort-header-sorted {
        .mat-sort-header-arrow {
            transform: scale(1.7) translateY(0px) !important;
        }
    }
    .mat-sort-header-arrow {
      display: none;
    }
}


