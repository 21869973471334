@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $grey: mat.define-palette(mat.$grey-palette);

  .sci-profile-gallery {
    .title {
      color: mat.get-color-from-palette($grey, 600);
    }

    // .profile:hover {
    //   .profile-about {
    //     background-color: mat-color($grey, 300);
    //   }
    // }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}