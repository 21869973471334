@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .sci-item-drawer {
    background-color: white;

    .back-button {
      color: mat.get-color-from-palette($primary);
    }

    .purchase-button {
      color: mat.get-color-from-palette($primary);
      border-color: mat.get-color-from-palette($primary);

      &:hover {
        background-color: mat.get-color-from-palette($primary);
        color: white;
      }
    }

    .details-makers {
      background-color: mat.get-color-from-palette($primary, lighter);
    }


    .language-selection {
      .option {
        color: mat.get-color-from-palette($accent);
        &.active {
          color: #7997a3;
        }
      }
    }

    .icon {
      background-color: mat.get-color-from-palette($primary, darker);
      color: white;
    }
  }
}


@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
