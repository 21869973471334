@use '~@angular/material' as mat;
@import '../../../styling/breakpoints';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $grey: mat.define-palette(mat.$grey-palette);
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);

  .quote {
    blockquote {
      font-size: 1.5rem;
      line-height: 1.5em;
      font-style: italic;
      text-align: center;
      margin-top: 0;
    }
  }

  mat-tab-group.tab-view {
    mat-tab-header {
      .mat-tab-label-container {
        .mat-ink-bar {
          display: none;
        }
        .mat-tab-labels {
          .mat-tab-label-active {
            border-bottom: 2px solid #8dc63f !important;
          }
          @media screen and (max-width: $mobile) {
            flex-direction: column; 
          }
        }
      }
    }
  }

}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}