@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .sci-call-out {
    .title {
      color: mat.get-color-from-palette($primary, default-contrast);
      background-color: mat.get-color-from-palette($primary, 500);
    }

    .body {
      background-color: mat.get-color-from-palette($accent, 100);

      .item {
        color: mat.get-color-from-palette($primary, 700);
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
