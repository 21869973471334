@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .sci-carousel {
    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: black;
      }
    }

    .swiper-pagination-bullet {
      border-color: #757575;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #8DC63F;
      border-color: #8DC63F;
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
