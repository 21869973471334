@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  .sci-search-overlay {
    .icon-search-close {
      svg {
        fill: grey;
      }
    }
    .icon-search-off {
      svg {
        fill: #7997a3;
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
