@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-purchase-modal {
    .purchase-info {
      color: #424242;
    }

    .instructions {
      color: mat.get-color-from-palette($primary, darker);
    }

    .purchase-form-container {
      background-color : mat.get-color-from-palette($primary);
    }

    .mat-input-element,
    .mat-select-value {
      color: white;
      caret-color: white;
    }

    mat-label {
      color: #e0e0e0;
    }

    .mat-form-field-underline,
    .mat-form-field-ripple {
      background-color: mat.get-color-from-palette($primary, darker) !important;
    }

    .shipping-info {
      .shipping-info-label {
        color: white;
        margin-top: 1rem;
      }
      .textarea {
        background-color: rgba(255,255,255,0.2);
        border-color: rgba(255,255,255,0.3);
      }
    }

    button {
      color: white;

      &:hover {
        color: black;
      }
    }

    .send {
      border-color: white;
      color: white;

      &:hover {
        background-color: white !important;
        color: mat.get-color-from-palette($primary);
      }
    }

    .screen2 {
      h1 {
        color: mat.get-color-from-palette($primary);
      }
      .button {
        border-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary);

        &:hover {
          background-color: mat.get-color-from-palette($primary) !important;
          color: white;
        }
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}