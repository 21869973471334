@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $grey: mat.define-palette(mat.$grey-palette);

  .sci-mobile-header-menu {
    border-top-color: rgba(0, 0, 0, 0.2);

    button.link {
      color: mat.get-color-from-palette($primary, lighter-contrast);
      border-radius: 0;
      
      &.active, &:hover {
        background-color: mat.get-color-from-palette($primary, 0.15);
      }
    }

    .top-border-overlay {
      background-color: white;
    }

    .filler {
      background-color: mat.get-color-from-palette($grey, 50);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
