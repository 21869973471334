@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-menu-icon {
    .icon .bar {
      background-color: mat.get-color-from-palette($primary, darker);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color != null {
    @include color($color);
  }
}
