@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-news-item {
    .news-item {
      color: black;
    }

    .thumb {
      border-color: white;
    }

    .date {
      color: mat.get-color-from-palette($primary, darker);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);

  .sci-news-item {
    .title {
      font-weight: 700;
      p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .identifier {
      font-weight: 300;
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
