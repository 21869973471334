@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-social-sidebar {
    border: 1px solid mat.get-color-from-palette($primary, default-contrast);

    .social-button {
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-color-from-palette($primary, default-contrast);
      border-bottom: 1px solid mat.get-color-from-palette($primary, default-contrast);

      :hover {
        background-color: mat.get-color-from-palette($primary, default-contrast);
        color: mat.get-color-from-palette($primary, 500);
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
