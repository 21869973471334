@use '~@angular/material' as mat;

@mixin theme($theme) {
  $primary: map-get($theme, primary);

  .sci-card-gallery {
    .title {
      color: mat.get-color-from-palette($primary, 500);
    }
  }
}