@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $grey: mat.define-palette(mat.$grey-palette);

  .sci-macroscopes {
    .highlight-content {
      border-bottom: 1px solid mat.get-color-from-palette($grey, 300);
    }

    .carousel-image-title {
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-color-from-palette($primary, default-contrast);
    }

    .more-button {
      background-color: mat.get-color-from-palette($primary, 100);
      color: mat.get-color-from-palette($primary, 500);
      border: 1px solid mat.get-color-from-palette($grey, 50);

      &:hover {
        background-color: mat.get-color-from-palette($primary, 500);
        color: mat.get-color-from-palette($primary, default-contrast);
      }
    }

    .mobile-button {
      border-top: 1px solid mat.get-color-from-palette($grey, 300);
      border-bottom: 1px solid mat.get-color-from-palette($grey, 300);
      color:  mat.get-color-from-palette($grey, 600);
    }

    .mobile-icon {
      color: mat.get-color-from-palette($grey, 600);
    }

    .fullscreen-modal {
      background-color: mat.get-color-from-palette($primary, default-contrast);
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
