@use '~@angular/material' as mat;

$tablet: 960px;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $accent: map-get($config, accent);
  $grey: mat.define-palette(mat.$grey-palette);

  .sci-contact {
    .section-1 {
      background-color: white;

      @media (max-width: $tablet) {
        background-color: mat.get-color-from-palette($grey, 50);
      }

      .email {
        color: black;
      }
    }

    .section-2 {
      background-color: mat.get-color-from-palette($grey, 50);
    }

    .section-3 {
      background-color: mat.get-color-from-palette($accent, lighter);
    }

    .link {
      color: mat.get-color-from-palette($accent);
    }

    .icon {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
    }


    .social-icons .icon:hover {
      color: white;
      background-color: mat.get-color-from-palette($accent);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
