@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-header, .sci-header-menu {
    background-color: white;
  }

  .sci-header .nav-item,
  .sci-header-menu button {
    color: mat.get-color-from-palette($primary, darker);
    border-radius: 0;

    &.active,
    &:hover {
      background-color: mat.get-color-from-palette($primary, 0.15);
    }
  }

  .sci-header {
    .sidenav-toggle.open {
      border-left-color: rgba(0, 0, 0, 0.2);
    }

    .mat-menu-panel {
      border-radius: 0;
    }
  }

  .cdk-overlay-container {
    .sci-header-menu {
      &.mat-menu-panel {
        min-height: unset;
        max-width: 360px;
      }
      .mat-menu-content {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
