@use '~@angular/material' as mat;


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);

  .sci-footer {
    .contact {
      background-color: mat.get-color-from-palette($primary, darker);
      color: mat.get-color-from-palette($primary, darker-contrast);

      .icon.svg {
        color: mat.get-color-from-palette($primary, darker);
      }

      .icon.font {
        color: white;
      }
    }

    .acknowledgement {
      background-color: black;
      color: white;
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include color($color);
  }
}
