@use '~@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $grey: mat.define-palette(mat.$grey-palette);
  $accent: map-get($config, accent);

  .sci-lazy-cards {
    .card-tray {
      .card {
        .card-header {
          background-color: mat.get-color-from-palette($accent, 100);
          color: mat.get-color-from-palette($primary, 700);
        }
      }
    }

    .more-button {
      color: mat.get-color-from-palette($primary, 500);
      background-color: mat.get-color-from-palette($primary, 100);
      border: 1px solid mat.get-color-from-palette($primary, 500);

      &:hover {
        background-color: mat.get-color-from-palette($primary, 500);
        color: mat.get-color-from-palette($primary, default-contrast);
      }
    }
  }
}

@mixin typography($config-or-theme) {
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color != null {
    @include color($color);
  }
  @if $typography != null {
    @include typography($typography);
  }
}
